import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/mdxLayouts/ProjectsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`The challenge`}</h2>
    <p>{`I was visiting family and when I was playing games with my little brother he was wanting to play a tic tac toe game on the TV all day. While playing this with him I thought to myself " I can make that". So I did. I gave myself the night to code and style the project. It wasn't the most challenging but it was a fun project to do on a time crunch.`}</p>
    <p>{`That night I also bought the domain render.games because Render is the name of my little brother and I thought it may be fun to make a game site just for him later down the road.`}</p>
    <h2>{`How it was made`}</h2>
    <p>{`I used React and Gatsby as the base for the project as these are two frameworks that I am very familiar with. I wanted to use Gatsby specifically because it is the easiest way to set up a PWA and I wanted to play the game on my phone. I also used Framer Motion for the animations because this library has a very pleasing spring animation that felt very kid-friendly.`}</p>
    <p>{`The logic of the game isn't anything special. I didn't want to look up other tic tac toe games done in JavaScript so I just figured it out as I was working on the project. I made it so if you hit a dead-end the game board would reset and you could keep playing. I also added the ability to change each player's name so if you are playing with your little brother or sister you don't have to keep track of whose turn it is.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`While this was just a quick game to mess around with it made my little brother happy. It was gratifying to play it with him and I can't wait to spruce up his game site. It was my birthday recently and he made me a card that had a bunch of tic tac toe boards on it and that just melted my heart. If you have a younger sibling or a child of your own you should definitely try making a simple game for them!`}</p>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      